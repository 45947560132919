export const noData = {
    "code": 0,
    "data": "",
    "desc": "string",
    "warning": "string",
    "exception": "string"
}
export const positionList = {
    "code": 200,
    "data": [
        {
            "electionId": "00000000-0000-0000-0000-000000000000",
            "electionTitle": "General election 1",
            "electionDay": "11/15/2021",
            "isMultiConfirm": true,
            "canAssignment": true,
            "positionList": [
                {
                    "eleJobId": "00000000-0000-0000-0000-000000000000",
                    "elePollingPlaceId": "00000000-0000-0000-0000-000000000000",
                    "eleJobTitle": "aaaaa",
                    "elePollingPlace": "abcde",
                    "placeType": 1,
                    "placeTypeStr": null,
                    "paymentAmount": 20.9,
                    "workDayList": [
                        {
                            "workDay": "11/01/2011",
                            "fromTime": "08:30am",
                            "toTime": "11:30am",
                            "eleFacilityName": "builed",
                            "eleFacilityAddress": "cccdd",
                            "status": 1,
                            "statusStr": "Accepted",
                            "displayButtons": [
                                {
                                    "name": 1,
                                    "visible": true
                                },
                                {
                                    "name": 2,
                                    "visible": true
                                },
                                {
                                    "name": 3,
                                    "visible": true
                                },
                                {
                                    "name": 4,
                                    "visible": false
                                }
                            ],
                            "eleJobTitleAssignmentId": "00000000-0000-0000-0000-000000000000"
                        },
                        
                    ]
                },
                {
                    "eleJobId": "00000000-0000-0000-0000-000000000000",
                    "elePollingPlaceId": "00000000-0000-0000-0000-000000000000",
                    "eleJobTitle": "aaaaa",
                    "elePollingPlace": "abcde",
                    "placeType": 4,
                    "placeTypeStr": null,
                    "paymentAmount": 20.9,
                    "workDayList": [
                        {
                            "workDay": "11/01/2011",
                            "fromTime": "08:30am",
                            "toTime": "11:30am",
                            "eleFacilityName": "builed",
                            "eleFacilityAddress": "cccdd",
                            "status": 1,
                            "statusStr": "Accepted",
                            "displayButtons": [
                                {
                                    "name": 1,
                                    "visible": true
                                },
                                {
                                    "name": 2,
                                    "visible": true
                                },
                                {
                                    "name": 3,
                                    "visible": true
                                },
                                {
                                    "name": 4,
                                    "visible": false
                                }
                            ],
                            "eleJobTitleAssignmentId": "00000000-0000-0000-0000-000000000000"
                        },
                        
                    ]
                }
            ],
            "displayButtons": [
                {
                    "name": 1,
                    "visible": true
                },
                {
                    "name": 2,
                    "visible": true
                },
                {
                    "name": 3,
                    "visible": true
                },
                {
                    "name": 4,
                    "visible": false
                }
            ]
        },
        {
            "electionId": "00000000-0000-0000-0000-000000000000",
            "electionTitle": "General election 2",
            "electionDay": "11/15/2021",
            "isMultiConfirm": false,
            "canAssignment": true,
            "positionList": [
                {
                    "eleJobId": "00000000-0000-0000-0000-000000000000",
                    "elePollingPlaceId": "00000000-0000-0000-0000-000000000000",
                    "eleJobTitle": "aaaaa",
                    "elePollingPlace": "abcde",
                    "placeType": 4,
                    "placeTypeStr": null,
                    "paymentAmount": 20.9,
                    "workDayList": [
                        {
                            "workDay": "11/01/2011",
                            "fromTime": "08:30am",
                            "toTime": "11:30am",
                            "eleFacilityName": "builed",
                            "eleFacilityAddress": "cccdd",
                            "status": 1,
                            "statusStr": "Accepted",
                            "displayButtons": [
                                {
                                    "name": 1,
                                    "visible": true
                                },
                                {
                                    "name": 2,
                                    "visible": true
                                },
                                {
                                    "name": 3,
                                    "visible": true
                                },
                                {
                                    "name": 4,
                                    "visible": false
                                }
                            ],
                            "eleJobTitleAssignmentId": "00000000-0000-0000-0000-000000000000"
                        },
                        {
                            "workDay": "11/01/2011",
                            "fromTime": "08:30am",
                            "toTime": "11:30am",
                            "eleFacilityName": "builed",
                            "eleFacilityAddress": "cccdd",
                            "status": 1,
                            "statusStr": "Accepted",
                            "displayButtons": [
                                {
                                    "name": 1,
                                    "visible": false
                                },
                                {
                                    "name": 2,
                                    "visible": true
                                },
                                {
                                    "name": 3,
                                    "visible": false
                                },
                                {
                                    "name": 4,
                                    "visible": false
                                }
                            ],
                            "eleJobTitleAssignmentId": "00000000-0000-0000-0000-000000000000"
                        }
                    ]
                },
                {
                    "eleJobId": "00000000-0000-0000-0000-000000000000",
                    "elePollingPlaceId": "00000000-0000-0000-0000-000000000000",
                    "eleJobTitle": "aaaaa",
                    "elePollingPlace": "abcde",
                    "placeType": 4,
                    "placeTypeStr": null,
                    "paymentAmount": 20.9,
                    "workDayList": [
                        {
                            "workDay": "11/01/2011",
                            "fromTime": "08:30am",
                            "toTime": "11:30am",
                            "eleFacilityName": "builed",
                            "eleFacilityAddress": "cccdd",
                            "status": 1,
                            "statusStr": "Accepted",
                            "displayButtons": [
                                {
                                    "name": 1,
                                    "visible": true
                                },
                                {
                                    "name": 2,
                                    "visible": true
                                },
                                {
                                    "name": 3,
                                    "visible": true
                                },
                                {
                                    "name": 4,
                                    "visible": false
                                }
                            ],
                            "eleJobTitleAssignmentId": "00000000-0000-0000-0000-000000000000"
                        }
                    ]
                }
            ],
            "displayButtons": [
                {
                    "name": 1,
                    "visible": true
                },
                {
                    "name": 2,
                    "visible": true
                },
                {
                    "name": 3,
                    "visible": true
                },
                {
                    "name": 4,
                    "visible": false
                }
            ]
        }
    ],
    "desc": "Successfully",
    "warning": "",
    "exception": null
}

export const settingMsg = {
    "code": 0,
    "data": {
        "pageName": "string",
        "description": "string",
        "picture": "string",
        "secondDescription": "string",
        "title": "string"
    },
    "desc": "string",
    "warning": "string",
    "exception": "string"
}

export const facilityMsg={
    "code":0,
    "data":{
        "faciliyList":[
            {
                "elePollingPlaceId":"3fa85f64-5717-4562-b3fc-2c963f66456",
                "faciliyId":"1",
                "name":"1",
                "address":"dsalkdsalkdjsakd",
                "photoList":[
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E4%BD%A0%E5%A5%BD",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E6%98%AF%E4%BD%A0",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E6%98%AF%E4%BB%96",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%B2%AB%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%B2%A4%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E8%8D%89%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%9D%92%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%BB%91%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E7%BF%98%E5%98%B4",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%B3%8A%E9%B1%BC"
                ]
            },
            {
                "elePollingPlaceId":"3fa85f64-5717-4562-b3fc-2c963f66a123",
                "faciliyId":"2",
                "name":"2",
                "address":"dsaldksalkdasd",
                "photoList":[
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E4%BD%A0%E5%A5%BD",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E6%98%AF%E4%BD%A0",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E6%98%AF%E4%BB%96",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%B2%AB%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%B2%A4%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E8%8D%89%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%9D%92%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%BB%91%E9%B1%BC",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E7%BF%98%E5%98%B4",
                    "https://fakeimg.pl/350x200/CFD8DC/000/?font=noto&text=%E9%B3%8A%E9%B1%BC"
                ]
            }
        ],
        "mapPoint":{
            "type":"FeatureCollection",
            "center":{
                "longitude":-114.07194871435547,
                "latitude":51.045519200657324
            },
            "features":[
                {
                    "type":"Feature",
                    "id":0,
                    "properties":{
                        "electionId":"3fa85f64-5717-4562-b3fc-2c963f66456",
                        "id":"1",
                        "title":"aaa",
                        "fillColor":"string",
                        "strokeColor":"string",
                        "strokeOpacity":"string",
                        "strokeWeight":"string",
                        "fillOpacity":"string",
                        "featureType":6
                    },
                    "geometry":{
                        "type":"Point",
                        "coordinates":[
                            -115.2323763,
                            50.08329149999999
                        ]
                    }
                },
                {
                    "type":"Feature",
                    "id":1,
                    "properties":{
                        "electionId":"3fa85f64-5717-4562-b3fc-2c963f66a123",
                        "id":"2",
                        "title":"aaa1",
                        "fillColor":"string",
                        "strokeColor":"string",
                        "strokeOpacity":"string",
                        "strokeWeight":"string",
                        "fillOpacity":"string",
                        "featureType":6
                    },
                    "geometry":{
                        "type":"Point",
                        "coordinates":[
                            -115.2323763,
                            51.08329149999999
                        ]
                    }
                }
            ]
        }
    },
    "desc":"string",
    "warning":"string",
    "exception":"string"
}

export const reasonData={
    "code":200,
    "data":{
        "reasonsRequired":true,
        "reasonsFree":false,
        "reasons":[
            {
                "value":"1",
                "text":"yes"
            }
        ]
    },
    "desc":"Successfully",
    "warning":"",
    "exception":null
}

export const mapData={
    "code": 200,
    "data": {
      "center": {
        "longitude": 152.813063,
        "latitude": -25.265773
      }
    },
    "desc": "Successfully",
    "warning": "",
    "exception": null
  }