<template>
  <div class="layoutContent">
    <div
      v-if="noDataShow"
      class="noDataBox"
    >
      <div
        class="noDataHtml"
        v-html="noData"
      />
    </div>
    <div v-else>
      <div class="headerTitle">
        <div v-html="SettingMsg.description" />
        <div class="searchBox">
          <div class="pop-flex mb-2 left">
            <k-form-select
              :options="electionList"
              v-model="form.electionId"
              class="mr-3 wid"
              value="tlt"
            />
            <k-form-datepicker
              v-model="form.startTime"
              :aria-label="$g('dob')"
              class="mr-3 wid"
            />
            <k-form-datepicker
              v-model="form.toDay"
              :aria-label="$g('dob')"
              class="mr-3 wid"
            />
            <k-form-select
              :options="statusList"
              v-model="form.status"
              class="mr-3 wid"
            />
            <b-icon
              icon="search"
              font-scale="2"
            />
          </div>
          <div class="right">
            <b-button
              type="button"
              variant="light"
              class="mr-3 btnBorder"
            >
              {{
                $g("assignPosition")
              }}
            </b-button>
            <b-button
              type="button"
              variant="light"
              class="mr-3 btnBorder"
            >
              {{
                $g("assignWork")
              }}
            </b-button>
          </div>
        </div>
      </div>
      <homeItem
        class="boxMargin"
        v-for="(itemOne, index) in model"
        :key="index"
        :item-title="itemOne.electionTitle"
      >
        <div
          v-for="(itemTwo, indexTwo) in itemOne.positionList"
          :key="indexTwo"
          class="tableMarginTop"
        >
          <div class="tableTitleBox">
            <div class="top">
              <div>
                <span>{{ $g("position") }}: {{ itemTwo.eleJobTitle }}</span>
              </div>
              <div>
                <b-button
                  v-if="itemOne.canAssignment"
                  type="button"
                  variant="light"
                  class="mr-3 btnBorder"
                >
                  {{ $g("assignWork") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </homeItem>
    </div>
  </div>
</template>

<script>
import homeItem from "./components/home-item.vue";
import {
  getPositions,
  getElectionList,
  getStatusList,
  getShownodata,
} from "@/api/workAssignments";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import { getPortalPageSetting } from "@/api/home";

export default {
  components: {
    homeItem,
  },
  data(){
    return {
      SettingMsg: {},
      form: {
        electionId: "",
        fromDay: "",
        toDay: "",
        status: "",
      },
      electionList: [],
      statusList: [],
      noDataShow: false,
      model: [],
      noData: null,
      fields: [
        {
          key: "workDay",
          label: "Day",
        },
        {
          key: "fromTime",
          label: "Start Time ",
        },
        {
          key: "toTime",
          label: "End Time",
        },
        {
          key: "eleFacilityName",
          label: "Work Location",
        },
        {
          key: "eleFacilityAddress",
          label: "Address",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
    };
  },
  mounted(){
    this.getPositions();
  },
  methods: {
    async getPositions(){
      let { data } = await getShownodata();
      this.noData = data;
      if(!data){
        getPortalPageSetting(pageNameEnum.WorkAssignmentList).then((res) => {
          this.SettingMsg = res.data;
        });
        getPositions(this.form).then((res) => {
          console.log(res);
          this.model = JSON.parse(JSON.stringify(res.data));
          this.model.forEach((ele) => {
            if(!ele.isMultiConfirm){
              ele.positionList.forEach((element) => {
                if(
                    element.placeType == 4 ||
                    element.placeType == 8 ||
                    element.placeType == 32
                ){
                  element.fields = this.fields;
                } else {
                  element.fields = [
                    {
                      key: "workDay",
                      label: "Day",
                    },
                    {
                      key: "fromTime",
                      label: "Start Time ",
                    },
                    {
                      key: "toTime",
                      label: "End Time",
                    },
                    {
                      key: "action",
                      label: "Action",
                    },
                  ];
                }
              });
            } else {
              ele.positionList.forEach((element) => {
                if(
                    element.placeType == 4 ||
                    element.placeType == 8 ||
                    element.placeType == 32
                ){
                  element.fields = [
                    {
                      key: "workDay",
                      label: "Day",
                    },
                    {
                      key: "fromTime",
                      label: "Start Time ",
                    },
                    {
                      key: "toTime",
                      label: "End Time",
                    },
                    {
                      key: "eleFacilityName",
                      label: "Work Location",
                    },
                    {
                      key: "eleFacilityAddress",
                      label: "Address",
                    },
                  ];
                } else {
                  element.fields = [
                    {
                      key: "workDay",
                      label: "Day",
                    },
                    {
                      key: "fromTime",
                      label: "Start Time ",
                    },
                    {
                      key: "toTime",
                      label: "End Time",
                    },
                  ];
                }
              });
            }
          });
          console.log(this.model);
        });
      } else {
        this.noDataShow = true;
      }
    },
    getSelectMsg(){
      getElectionList().then((res) => {
        this.electionList = res.data;
        this.electionList.unshift({ text: "Election", value: "" });
      });
      getStatusList().then((res) => {
        this.statusList = res.data;
        this.statusList.unshift({ text: "Status", value: "" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layoutContent {
  .noDataBox {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    .noDataHtml {
      width: 50%;
    }
  }

  .headerTitle {
    margin-bottom: 25px;

    .searchBox {
      display: flex;
      margin: 25px 0;

      .left {
        width: 80%;

        .wid {
          width: 20%;
        }
      }

      .right {
        width: 20%;
        text-align: right;
      }
    }
  }

  .boxMargin {
    margin-bottom: 50px;

    .tableMarginTop {
      margin-bottom: 50px;
    }

    .tableTitleBox {
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        span {
          margin-right: 70px;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
    }
  }

  .btnBorder {
    border: 3px solid #000;
    border-radius: 5px;
  }
}
</style>
