import http from '../../node_modules/fe-infrastractures/src/utils/http'
const isLocalData = false;

import {
    positionList,
    noData,
    settingMsg,
    facilityMsg,
    reasonData,
} from "@/views/workAssignments/data"
import { globalEnv } from "../../../fe-infrastractures";

export const getShownodata = () => {
    if (isLocalData) {
        return Promise.resolve(noData);
    }
    return http.get("/api/work-assignment/is-show-nodata", { loading: true });
};

export const getSettingMsg = (data) => {
    if (isLocalData) {
        return Promise.resolve(settingMsg);
    }
    return http.post("/anon/portal-page/setting", data, { loading: true, useBody: true });
};

export const getPositions = (data) => {
    if (isLocalData) {
        return Promise.resolve(positionList);
    }
    return http.post("/api/work-assignment/positions", data, { loading: true, useBody: true });
};

export const getElectionList = () => {
    return http.get("/api/lists/usable-elections",);
};

export const getStatusList = () => {
    return http.get("/api/enum/job-confirm-status",);
};

export const getDetailDescription = (data) => {
    return http.get("/api/work-assignment/job-description", data, { loading: false, });
};


export const getOtherStaffsList = (data) => {
    return http.get("/api/work-assignment/list/other-staffs", data, { loading: false, });
};

export const getElefacility = (data) => {
    if (isLocalData) {
        return Promise.resolve(facilityMsg);
    }
    return http.get("/api/work-assignment/list/ele-facility", data, { loading: true, });
};

export const getElePositionList = (data) => {
    return http.get("/api/work-assignment/drop-down-list/position", data, { loading: false, });
};


export const getViewMoreInformationShow = (data) => {
    return http.post(`/api/work-assignment/can-see-contact-info/${data.electionId}/${data.eleJobId}`, data.faciliyIdArray, { loading: true, useBody: true });
};
export const getViewMoreInformation = (data) => {
    return http.get("/api/work-assignment/eleFacility-contact-details", data, { loading: true, useBody: true });
};

export const getViewSupervisorContact = (data) => {
    return http.post("api/work-assignment/view-supervisor--contact", data, { loading: true, useBody: true });
};

export const saveAssignPosition = (data) => {
    return http.post("/api/work-assignment/actions/add-to-position", data, { loading: true, useBody: true });
};

export const getLocationTypeList = (data) => {
    return http.get("/api/work-assignment/location-type");
};

export const getWorkLocationList = (data) => {
    return http.post("/api/work-assignment/work-location", data, { loading: true, useBody: true });
};

export const getAssigndWorkerConfiguration = () => {
    return http.get(`/api/work-assignment/actions/assignment-over-target`);
};


export const getAssignmentTarget = (data) => {
    return http.post("/api/work-assignment/assignment-target", data, { loading: true, useBody: true });
};
export const getAssignmentTargetForEdit = (data) => {
    return http.post("/api/work-assignment/for-edit", data, { loading: false, useBody: true });
};


export const saveAssignment = (data) => {
    return http.post("/api/work-assignment", data, { loading: true, useBody: true });
};

export const saveAssignmentMulti = (data) => {
    return http.post("/api/work-assignment/multi-save", data, { loading: true, useBody: true });
};

export const checkReasonsType = (data) => {
    if (isLocalData) {
        return Promise.resolve(reasonData);
    }
    return http.get("/api/work-assignment/accepted-declined-reasons", data, { loading: false, });
};

export const multiConfirmStatus = (data) => {
    return http.post("/api/work-assignment/confirm-status-multi", data, { loading: true, useBody: true });
};

export const oneConfirmStatus = (data) => {
    return http.post("/api/work-assignment/confirm-status", data, { loading: false, useBody: true });
};

export const sendAMessage = (data) => {
    return http.post("/api/work-assignment/actions/send-a-message", data, { loading: true, useBody: true });
};


export const getMessageHistoryList = (data) => {
    return http.post("/api/work-assignment/list/message-history", data, { loading: true, useBody: true });
};
// todo ..
export const setContactStatus = (data) => {
    return http.post("/api/work-assignment/actions/other-staffs/contacted", data, { loading: true, useBody: true });
};

export const getComments = (data) => {
    return http.post("/api/work-assignment/actions/other-staffs/comments", data, { loading: true, useBody: true });
};

export const getAccessibilityPhotos = (eleFacilityId) => {
    return http.get(`/api/work-assignment/${eleFacilityId}/accessibility-infos`, null, {
        loading: false,
        baseURL: globalEnv.VUE_APP_STD_URL,
    });
};

export const getCanAssignedWorkersSeeFacility = (eleJobId) => {
    return http.get(`api/work-assignment/${eleJobId}/can-assigned-workers-see-facility`);
};
